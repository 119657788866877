<template>
  <div>
    <h5 class="my-8 ml-2">{{$t('TAB.SETTINGS_DNS.TITLE')}}</h5>

    <b-row class="justify-content-end my-4 mx-1">
      <b-button variant="primary" @click="new_email_clicked()">(+) {{$t('TAB.SETTINGS_DNS.NEW_ADDRESS')}}</b-button>
    </b-row>

    <b-table
      id="table"
      class="mh-100"
      :fields="headers"
      :items="items"
      head-variant="light"
      sticky-header
      hover
      >
      <template #cell(actions)="data">
        <div class='d-flex justify-content-end'>
          <span>
            <button class="btn btn-icon btn-light btn-sm mr-4" @click="delete_email_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/trash2.svg"></inline-svg>
              </span>
            </button>
          </span>
        </div>
      </template>
    </b-table>

    <b-modal ref="new-address-modal" title="Ny adress" @ok="save_email_clicked()">
      <b-form-group id="email-group" label="Epostadress" label-for="email-field">
        
          <b-form-input
            id="email-field"
            v-model="new_email"
        
          ></b-form-input>
          <b-form-invalid-feedback>{{$t('TAB.SETTINGS_DNS.INVALID_EMAIL')}}</b-form-invalid-feedback>
        
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="secondary" @click="cancel()">{{$t('COMMON.CANCEL')}}</b-button>
        <b-button variant="primary" @click="ok()">{{$t('COMMON.SAVE')}}</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

export default {

  name: 'EmailsTab',
  mixins: [ toasts ],

  components: {

  },

  computed: {
    ...mapGetters(['currentCompanyId']),
  },

  watch: {
  },

  methods: {

    new_email_clicked() {
      this.$refs['new-address-modal'].show();
    },

    save_email_clicked() {
      this.$refs['new-address-modal'].show();
      axios
        .post(`/email/valid`, {email: this.new_email})
        .then(res => {
          if (res.status === 201) {
            this.items.push(res.data);
            this.toastr('success', this.$t('COMMON.OK'), this.$t('TAB.SETTINGS_DNS.ADDED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.SETTINGS_DNS.UNABLE_ADD'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.SETTINGS_DNS.UNABLE_ADD'));
        });
    },

    delete_email_clicked(email) {
      axios
        .delete(`/email/valid/${email.id}`)
        .then(res => {
          if (res.status === 204) {
            this.items = this.items.filter(item => item.id !== email.id);
            if (this.template && this.template.id === template.id) { this.template = null; }
            this.toastr('success', this.$t('COMMON.OK'), this.$t('TAB.SETTINGS_DNS.DELETED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.SETTINGS_DNS.UNABLE_DELETE'));
          }
        }).catch(err => {
          console.log(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.SETTINGS_DNS.UNABLE_DELETE'));
        });
    },

    load_emails() {
      this.items = [];
      const loader = this.$loading.show();
      axios
        .get(`/email/valid`)
        .then(res => {
          if (res.status === 200) {
            this.items = res.data;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.SETTINGS_DNS.UNABLE_LIST'));
          }
          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.SETTINGS_DNS.UNABLE_LIST'));
          loader && loader.hide();
        });

    },
  },

  mounted() {
    this.load_emails();
  },

  data() {
    return {
      new_email: '',
      items: [],
      headers: [
        {
          key: 'email',
          show: true,
          sortable: true,
          label: 'E-mail',
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'is_postmark_valid',
          show: true,
          sortable: true,
          label: 'Postmark',
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
          formatter: (_, __, item) => {
            return item.is_postmark_valid ? this.$t('COMMON.YES') : this.$t('COMMON.NO')
          },
        },
        {
          key: 'actions',
          show: true,
          sortable: false,
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'w-100',
        }
      ]
    };
  }
};

</script>
