<template>
  <div class="container">

    <memlist-modal
      size="xl"
      ref="edit-form"
      :visible="show_modal"
      @close="closed"
      hide-footer
      >
      <div id="scroll-to-me"></div>

      <div v-if="page == 0" id="page-1" style="max-width: 800px; margin: auto;">
        <div v-if="term" v-html="term.html"></div>        

        <b-row>
          <b-col md="12">
            <memlist-checkbox 
              :text="$t('SETTINGS.KIVRA_TERMS')"
              v-model="accept_kivra_terms"
            />

          </b-col>
        </b-row>

        <button
          class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4 mt-4"
          type="submit"
          :disabled="!accept_kivra_terms"
          @click="next_page(1)"
        >
        {{$t('COMMON.CONTINUE')}}
        </button>

      </div>

      <div v-if="page == 1" id="page-2" style="max-width: 800px; margin: auto;">
        <div v-html="term.html"></div>

        <b-row>
          <b-col md="12">
            <memlist-checkbox 
              :text="$t('SETTINGS.KIVRA_PRIVACY_POLICY')"
              v-model="accept_kivra_privacy_policy"
            />

          </b-col>
        </b-row>

        <button
          class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4 mt-4"
          type="submit"
          :disabled="!accept_kivra_privacy_policy"
          @click="next_page(2)"
        >
        {{$t('COMMON.CONTINUE')}}
        </button>

      </div>

      <div v-if="page == 2" style="max-width: 800px; margin: auto;">

        <div v-if="creating" class="d-flex justify-content-center mb-8 mt-8">
      
          <memlist-spinner />
        
        </div>
    
        <div v-else-if="success">
          <div class="row">
            <div class="col-12 text-center">
              <i class="fa fa-check-circle" style="font-size: 42px; color: #00b315;"></i>
            </div>
          </div>
          <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
            <div class="col-12 text-center">
              <h4 class="event-name" style="text-align: center;">{{ $t('SETTINGS.KIVRA_TENANT_CREATED') }}</h4>
              <br />
              <p>
                {{ $t('SETTINGS.KIVRA_TENANT_CREATED_INFO', { org_number: company.org_number }) }}
              </p>
              <p>
                {{ $t('SETTINGS.KIVRA_AUTOGIRO_INFO') }}
              </p>
              <p><a href="">{{ $t('COMMON.CLICK_HERE')}}</a></p>
            </div>
          </div>

          <div style="margin: auto; text-align: center;">
            <button
              class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4 mt-4"
              type="submit"
              @click="close"
            >
            {{$t('COMMON.OK')}}
            </button>
          </div>
          
        </div>
        <div v-else-if="!success">
          <div class="row">
            <div class="col-12 text-center">
              <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
            </div>
          </div>
          <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
            <div class="col-12 text-center">
              <h4 class="event-name" style="text-align: center;">{{ $t('SETTINGS.KIVRA_UNABLE_CREATE') }}</h4>
              <br />
              <p>
                {{ $t('SETTINGS.KIVRA_UNABLE_CREATE_INFO', { org_number: company.org_number }) }}
              </p>
              
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                class="reg-button btn btn-primary font-weight-bold text-uppercase px-9 py-4 mt-4"
                type="submit"
                @click="close"
              >
              {{$t('COMMON.OK')}}
              </button>
            </div>
          </div>

          
        </div>

      </div>


  </memlist-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'KivraOnboardingModal',

  props: [],
  emits: ['created', 'updated'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId','companies']),

    company() {
      return this.companies.find(item => item.company_id === this.currentCompanyId) || {};
    }

  },

  methods: {

    closed() {
      this.show_modal = false;
      this.$emit('cancelled');
    },
    async get_term(type) {
      const res = await axios.get(`/company/term/type/${type}`);

      if (res.status === 200) {
        return res.data;
      }

      throw 'no template found';
    },

    async next_page(page) {
      this.show_modal = false;
      
      const loader = this.$loading.show();

      setTimeout(async () => {
        window.scrollBy(0,-20000);
        this.show_modal = true;
        this.page = page;

        try {
          if (this.page === 0) {
            this.term = await this.get_term('KIVRA_TERMS');
          }
          else if (this.page === 1) {
            this.term = await this.get_term('KIVRA_PRIVACY_POLICY');
          }
        }
        catch (err) {
          console.error('get term error', err);
        }
        
        loader && loader.hide();
      }, 1000);


      if (this.page === 1) {

        this.creating = true;

        const res = await axios.put(`/company/company_settings/${this.currentCompanyId}`, { enabled_kivra: true });
        
        if (res.status === 200) {
          this.creating = false;
          this.success = true;
          this.$emit('created', res.data);
        }
        else {
          this.creating = false;
          this.success = false;
        }
      }
      
    },

    on_save_clicked() {},


    cancelled() {
      this.show_modal = false;
      this.$emit('cancelled');
    },

    close() {
      this.hide();
    },

    show() {
      this.show_modal = true;
      
      this.next_page(0);
    },

    hide() {
      this.show_modal = false;
    },

    created(item, close) {
      if (close) {
        this.show_modal = false;
      }

      this.$emit('created', item);
    },
    updated(item, close, notify) {
      if (close) {
        this.show_modal = false;
      }
      
      this.$emit('updated', item, notify);
    }
  },

  mounted() {


  },

  data() {
    return {
      term: null,
      success: true,
      creating: false,
      page: 0,
      show_modal: false,
      accept_kivra_terms: false,
      accept_kivra_privacy_policy: false
    };
  }
};

</script>


<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";

ul li {
  margin-bottom: 24px;
  padding-left: 36px;
  position: relative;
}

ul {
  list-style: none;
  margin-bottom: 48px;
  margin-top: 0;
  padding-inline-start: 0;
}

li:before {
  color: #22201c;
  content: "●";
  display: inline-block;
  left: 0;
  position: absolute;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
}

</style>
